<template lang="pug">
.main
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu
            .container-xxl

                .row.no-gutters.submenu-inner
                    .col-7
                        .headline-submenu News
                    
                .row.no-gutters.submenu-inner
                
                    .col-md-12    
                        .menu                
                            router-link(to="/news/browse").mr-5 News 
                            router-link(to="/news/curate").mr-5 Curator
                           
              
    .container-xxl
        .row.no-gutters
            .col-12
                router-view(name="main").main-view
            
</template>

<script>
export default {
  name: 'SubmenuEcosystem',
  props: {
    title: String,
    
  },

  data: function () {
      return {
          selectAll: false
      }
  },

  mounted: function() {
     
  },

  methods: {


    select() {
   
        
        if(this.selectAll) {
            this.$store.dispatch('selectAll');
        } else {
            this.$store.dispatch('deselectAll');
        }
        
    },

    addRecords: function() {

      this.$store.dispatch('addSelectedRecords');
    }
  },
  computed: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';
 

</style>
